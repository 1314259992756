<template>
  <CreativeRotation v-if="!isDirectSold" />
  <CreativesSelect />
</template>

<script setup lang="ts">
import CreativeRotation from './CreativeRotation.vue'
import { CreativesSelect } from './CreativesSelect'
import { useCardCreatives } from './useCardCreatives'

const { isDirectSold } = useCardCreatives()!
</script>
