import { createInjectionState, reversedDebouncedRef } from '@lasso/shared/utils'
import { UseFormAddonsContext, useApiInfinite } from '@lasso/shared/hooks'
import { ChannelType, EndemicFormatType, useActivationApi } from '@lasso/api-activation/activation'
import { Ref, computed, ref, toRef } from 'vue'

import { RefOrGetter } from '@lasso/shared/types'

import { buildCreative } from '../utils'
import { CardCreativesFormInput } from '../types'

export const [useProvideCreativesSearch, useCreativesSearch] = createInjectionState(({
  isCardEnabled,
  channelId,
  endemicFormatId,
  marketerId,
  accountId,
  getId,
  useFieldError,
}: {
  isCardEnabled: RefOrGetter<boolean>
  channelId: Readonly<Ref<ChannelType>>
  endemicFormatId: Readonly<Ref<EndemicFormatType | null>>
  marketerId: Readonly<Ref<number>>
  accountId: Readonly<Ref<number>>
} & UseFormAddonsContext<CardCreativesFormInput>) => {
  const searchQuery = ref('')
  const searchQueryTrimmed = computed(() => searchQuery.value.trim())
  const searchQueryDebounced = reversedDebouncedRef(searchQuery, 300)

  const total = ref(0)
  let isLoadingAll = false

  const {
    fetching,
    data,
    onData,
    error,
    request,
    requestNextPage,
    hasNextPage,
  } = useApiInfinite(
    useActivationApi().getListOfCreativesByMarketer,
    (skip) => {
      return [{
        marketerId: marketerId.value,
        advertiserId: accountId.value,
        channelId: channelId.value,
        endemicFormatId: channelId.value === ChannelType.ENDEMIC_EHR
          ? EndemicFormatType.DISPLAY_ONLY
          : endemicFormatId.value ?? undefined,
        keyword: searchQueryTrimmed.value,
        paging: {
          pageSize: isLoadingAll
            ? total.value ?? 200
            : 20,
          skip,
        },
      }]
    },
    {
      getNextPageParam: ({ responseData, curPageParam: skip }) => {
        const nextSkip = skip + (responseData.data?.length ?? 0)

        return (responseData.total ?? 0) > nextSkip ? nextSkip : null
      },
      getDataArray: ({ data }) => data ?? [],
      initialPageParam: 0,
      refetch: true,
      cache: true,
      clearWhenDisabled: true,
      enabled: toRef(isCardEnabled),
    },
  )

  onData((data, [requestData]) => {
    if (!requestData?.paging.skip) {
      total.value = data.total ?? 0
    }
  })

  const loadAllResults = async () => {
    isLoadingAll = true
    await request()
    isLoadingAll = false
  }

  const searchedCreatives = computed(() => {
    return data.value.map(buildCreative)
  })

  const isShowingRecentCreatives = computed(() => {
    return !fetching.value && !searchQueryTrimmed.value && searchedCreatives.value.length > 0
  })

  const creativesError = useFieldError('creatives.creatives')

  return {
    fetching,
    error,
    request,
    searchQuery,
    searchQueryDebounced,
    isShowingRecentCreatives,
    requestNextPage,
    hasNextPage,
    searchedCreatives,
    total,
    getId,
    creativesError,
    loadAllResults,
  }
})
